import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Gemini.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Gemini
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/gemini"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Gemini</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm"></h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Gemini </h1>
                <h2 className="text-sm md:text-base ml-4">May 21 - Jun 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-love");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-nature");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-man");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-traits");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-facts");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
              <h1 className="playfair text-black text-xl md:text-3xl">About Gemini </h1>
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Sanskrit/Vedic name:
              </p>{" "}
              Mithun
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Gemini Meaning
              </p>
              : The Twins
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Type:{" "}
              </p>
              Air-Mutable-Positive
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Gemini Sign
              </p>
              <br />
              <br />
              Gemini Sign Gemini is the third sign of the zodiac circle and rules the third house. The sign is all about thinking fast, communicating effectively, and being full of ideas. It shows the distinct side of one, which often makes others confused. It is the liveliest air sign and one of the most emotionally intelligent and talkative signs.
              <br></br>
              <br></br> 
              Our report will provide you or your loved one with in-depth knowledge of life-threatening diseases, conditions, and symptoms to make better decisions about your health and care. This report also includes a list of questions you or your loved one can ask the doctor to determine if you are a good candidate for our “Know Everything” report and a list of specific medications we offer related to health, career, finance, and relationship.
              <br />
              <br />
              <p className="playfair text-black text-xl md:text-3xl">
                How to recognize Gemini?
              </p>
              <br />
              The natives born on and between May 20 and June 21 belong to the Gemini Sun Sign. The word “Gemini” is derived from the Latin word “Gemini,” which means “twin.”
              <br></br>
              <br></br>
              But when you look at the zodiac sign of Gemini, you’ll realize that the two signs are nothing alike. Yes, the two signs share an identical position in the zodiac, but they are as different as the two signs can be. In this article, we’ll take a closer look at the two signs and how to recognize them in the horoscope
              <br />
              <br />
              Geminis are the most social of all signs, which means they’re great at communicating with other people. They’re also very articulate, so they’re such good public speakers. Gemini people are often described as charismatic, witty, and energetic, and they have a way of making other people feel comfortable even when they’re the only person in the room. However, while sociability and communication are two of the most obvious ways Geminis show their nature, they are not the only ways.
              <br></br>
              <br></br>
              Gemini is one of the most complex signs in the zodiac. It is a mixture of many different personality traits, making it hard to pin down. Most people think of Geminis as quirky, chatty people. They are often described as “two-faced” and “twisted” because they can be incredibly charming and outgoing, distant and detached the next.
              <br></br>
              <br></br>
              The sign of Gemini is one of the most complexes in the zodiac. There are two Geminis in every group, and they have different ideas, opinions, and approaches to life. This makes it difficult for two Geminis to form a lasting partnership, so Geminis often pair off in pairs. However, some general tendencies apply to all Geminis, regardless of their partner.
              <br></br>
              <br></br>
              Gemini is the sign of the twins. People born under the constellation of Gemini are curious and social, which makes them excellent at communication and people skills. They are very interested in and open to learning about other people and their experiences, which helps them to understand the perspectives of others. Gemini people are generally very approachable, so it’s not uncommon for people to come across as hyper-friendly to them – but don’t be fooled; these are just their friendly demeanor.
              To know more about your personality traits, let’s explore about
              <br>
              </br>
              <br />
              <p className="playfair text-black text-xl md:text-3xl">All About Gemini</p>
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Domicile:
              </p>{" "}
              Mercury
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Detriment:
              </p>{" "}
              Jupiter
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Exaltation:
              </p>{" "}
              The Moon’s North Node (Rahu)
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Fall:
              </p>
              The Moon’s South Node (Ketu)
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Element:
              </p>
              Air
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Quality:
              </p>
              Mutable
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Symbol:
              </p>
              The Twins
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Tarot card:
              </p>{" "}
              The lovers for life
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Fellow signs:
              </p>{" "}
              Libra, Aquarius <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Compatible signs:{" "}
              </p>
              Aries, Gemini, Libra, Aquarius, Sagittarius
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Most Compatible Signs:{" "}
              </p>
              Sagittarius
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Opposite Signs:
              </p>{" "}
              Cancer, Capricorn
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                The Symbol denotes:
              </p>{" "}
              Keenness, wavering attitude, duality, communicative approach, creation, and swiftness.
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Characteristics:{" "}
              </p>
              Positive, Masculine, Duality
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Noteworthy qualities:
              </p>{" "}
              Cleverness, expressiveness, eloquence, idealism, resourcefulness, bravery, patience, humanitarianism, responsiveness.
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Undesirable personality traits:{" "}
              </p>
              Turmoil, verbosity, effusiveness, trickiness, shiftlessness, disobedience, impulsiveness, lack of concentration, exaggeration.
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Favorable things:
              </p>
              Smartphones, racing cars, fashionable clothes, esoteric music, guitar, books, novels, comedy shows, and clubs
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Things they hate:
              </p>
              narrow-minded people, dress codes, authority figures, quiet, monotonous work, pieces of training.
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Birthstone:
              </p>{" "}
              Emerald, Jade, and Green Onyx
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Lucky stones:{" "}
              </p>
              Agate, Aquamarine
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Lucky Metals:
              </p>{" "}
              Mercury, quicksilver
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Lucky day:{" "}
              </p>
              Wednesday
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Lucky number:
              </p>{" "}
              5, 14, 23, 32, 41, 50
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Birth Colours:{" "}
              </p>
              All shades of green, pale blue, yellow and pink
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Deepest need:
              </p>{" "}
              Communication with loved ones
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Lifetime wish:
              </p>{" "}
              As humans, we are curious creatures. just To get my answer to everything
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Mantra for life:{" "}
              </p>
              It is our inherent nature to learn and grow. To explore and discover. To seek knowledge and understanding. This is who we are, and this is what we were made to do.
              <br />
              <br />
              The quality most needed for balance: Deep thoughts, rather than being superficial or artificial.
              <br />
              <br />
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
